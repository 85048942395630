<template>
  <v-container fluid>
    <p>Aktuell noch nicht verfügbar, bitte über "Vor Wettkampf" -> "Mannschaften" -> "Geräteaufstellung" ändern.</p>
  </v-container>
</template>

<script>
export default {
  name: 'penalty'
}
</script>

<style scoped>

</style>
